/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
	font-family: 'iCiel-Gotham-Bold';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/iCeil-gotham/iCiel-Gotham-Ultra.ttf');
}

@font-face {
	font-family: 'iCiel-Gotham-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/iCeil-gotham/iCiel-Gotham-Medium.ttf');
}

@font-face {
	font-family: 'iCiel-Gotham-Thin';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/iCeil-gotham/iCiel-Gotham-Thin.ttf');
}

@font-face {
	font-family: 'Roboto-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
	font-family: 'Roboto-Thin';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/roboto/Roboto-Thin.ttf');
}

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #1e191a;
  --ion-color-primary-rgb: 30,25,26;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1a1617;
  --ion-color-primary-tint: #353031;

	/** secondary **/
	--ion-color-secondary: #0048bb;
  --ion-color-secondary-rgb: 0,72,187;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #003fa5;
  --ion-color-secondary-tint: #1a5ac2;

	/** tertiary **/
	--ion-color-tertiary: #cec1b6;
  --ion-color-tertiary-rgb: 206,193,182;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #b5aaa0;
  --ion-color-tertiary-tint: #d3c7bd;

	/** success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/** dark **/
	--ion-color-dark: #222;
	--ion-color-dark-rgb: 34, 34, 34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #111;
	--ion-color-dark-tint: #444;
	
	/** gray **/
	--ion-color-gray: #9b9b9b;
	--ion-color-gray-rgb: 155, 155, 155;
	--ion-color-gray-contrast: #ffffff;
	--ion-color-gray-contrast-rgb: 255, 255, 255;
	--ion-color-gray-shade: #858585;
	--ion-color-gray-tint: #a1a1a1;
	
	/** greenlight **/
		--ion-color-greenlight: #C1D5AE;
  	--ion-color-greenlight-rgb: 193,213,174;
  	--ion-color-greenlight-contrast: #000000;
  	--ion-color-greenlight-contrast-rgb: 0,0,0;
  	--ion-color-greenlight-shade: #aabb99;
		--ion-color-greenlight-tint: #c7d9b6;
		
	/** light **/
	--ion-color-light: #ebebeb;
	--ion-color-light-rgb: 235, 235, 235;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d4d4d4;
	--ion-color-light-tint: #f1f1f1;

	/** superlight **/
	--ion-color-superlight: #f4f4f4;
	--ion-color-superlight-rgb: 244, 244, 244;
	--ion-color-superlight-contrast: #000000;
	--ion-color-superlight-contrast-rgb: 0, 0, 0;
	--ion-color-superlight-shade: #ebebeb;
	--ion-color-superlight-tint: #f9f9f9;

	
	/** white **/
	--ion-color-white: #fff;
	--ion-color-white-rgb: 255, 255, 255;
	--ion-color-white-contrast: #222;
	--ion-color-white-contrast-rgb: 34, 34, 34;
	--ion-color-white-shade: #d7d8da;
	--ion-color-white-tint: #f5f6f9;
	
	/** black **/
	--ion-color-black: #111;
	--ion-color-black-rgb: 17, 17, 17;
	--ion-color-black-contrast: #eee;
	--ion-color-black-contrast-rgb: 238, 238, 238;
	--ion-color-black-shade: #000;
	--ion-color-black-tint: #555;
	
	/** form **/
	--ion-color-form-label: #b0abab;
	--ion-color-form-label-dark: #1e191a;
	--ion-color-note: #cbc6c6;
	
	/** custom values **/
	--ion-color-title2: #1073d0;
	--ion-color-box-holder: transparent;
	--ion-separator-color: #e6e6e6;
	--ion-feedback-label: #727272;
	--ion-top-small: 5px;
	--ion-top-medium: 30px;
	--ion-feedback-star: #9ba1a5;
	
	/** original theme file colors **/
	--ion-headings-font-weight: 300;
	
	--ion-color-angular: #ac282b;
	--ion-color-communication: #8e8d93;
	--ion-color-tooling: #fe4c52;
	--ion-color-services: #fd8b2d;
	--ion-color-design: #fed035;
	--ion-color-workshop: #69bb7b;
	--ion-color-food: #3bc7c4;
	--ion-color-documentation: #b16be3;
	--ion-color-navigation: #6600cc;
	
	--ion-padding: 32px;
	--ion-toolbar-min-height: 48px;
	
	--ion-card-radius: 20px;
	--ion-item-color: #999;
	--ion-item-background: transparent;
	--ion-text-color: var(--ion-color-primary);
	--ion-circle-color: rgb(232,231,233);
	
	/** fonts **/
	--ion-font-family: 'Roboto-Regular';
	--ion-default-font: 'Roboto-Regular';
	--ion-default-font-regular: 'iCiel-Gotham-Regular';
	--ion-default-font-bold: 'iCiel-Gotham-Bold';
	--ion-default-font-thin: 'iCiel-Gotham-Thin';
	--ion-font-size: 13px;

	/* okx vars */
	--okx-drawer-max-width: 280px;
	--okx-small-drawer-max-width: 90px;
	--okx-drawer-width: var(--okx-drawer-max-width);
	--okx-drawer-background: #23386e;
	--okx-drawer-background-rgb: rgb(35, 56, 110);
	--okx-sidebar-width: var(--okx-drawer-max-width);
	--okx-flex-min: 5px;
	--okx-flex-spacer: 20px;
	--okx-big-label-font-size: 42px;
	--okx-big-label-color: var(--ion-color-primary);
	--okx-big-label-margin: 2px 0;
	--okx-big-label-padding: 0;
	--okx-title-font-size: 20px;
	--okx-title-color: var(--ion-color-primary);
	--okx-title-margin: 2px 0;
	--okx-title-padding: 0;
	--okx-subtitle-font-size: 16px;
	--okx-subtitle-color: var(--ion-color-primary);
	--okx-subtitle-margin: 25px 0;
	--okx-subtitle-padding: 0;
	--okx-sectiontitle-font-size: 14px;
	--okx-sectiontitle-color: var(--ion-color-primary);
	--okx-sectiontitle-margin: 20px 0;
	--okx-sectiontitle-padding: 0;
	--okx-font-size: var(--ion-font-size);
	--okx-small-text-font-size: 11px;
	--okx-note-font-size: 12px;
	--okx-error-font-size: 11px;
	--okx-error-margin: 0 0 10px;
	--okx-hr-margin: 20px auto;
	--okx-border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
	--okx-drawer-padding: 32px;
	--okx-box-radius: 10px;
	--okx-box-padding-left: 15px;
	--okx-box-padding-top: 15px;
	--okx-box-padding-right: 15px;
	--okx-box-padding-bottom: 15px;
	--okx-small-radius: 5px;
	--okx-normal-text-color: var(--ion-text-color);
	--okx-light-text-color: var(--ion-color-gray);
	--okx-circle-color: var(--ion-circle-color);
	--okx-incrementer-button-width: 40px;
	--okx-loyalty-badge-width: 40px;
	--okx-loyalty-badge-inner-padding: 4px;
	--okx-loyalty-bar-height: 50px;
	--okx-color-border-dash:#2D2B2B; 
	--okx-cancel-button-color: rgba(255, 255, 255, .2);
	--okx-scrollbar-handle-color: var(--ion-color-secondary);
	--okx-scrollbar-track-color: var(--ion-color-light);
	--okx-scrollbar-color: var(--okx-scrollbar-handle-color) var(--okx-scrollbar-track-color);
	--okx-color-black: #000;
	--opx-background-color: #f8f6f4;
	--opx-background-color-rgb: rgb(248, 246, 244);
}

.web {
	--ion-item-border-color: #000;
	--ion-item-border-color: #eee;
	--okx-bsmodal-top: 180px;
	--okx-bsmodal-width-lg: 500px;
	--okx-bsmodal-width-md: 420px;
	--okx-bsmodal-width-sm: 390px;
}

/*
 * Dark Colors
 * -------------------------------------------
 */

body.light-gray-theme {
  --ion-color-primary: #1e191a;
  --ion-color-primary-rgb: 30,25,26;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1a1617;
  --ion-color-primary-tint: #353031;

  --ion-color-secondary: #0048bb;
  --ion-color-secondary-rgb: 0,72,187;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #003fa5;
  --ion-color-secondary-tint: #1a5ac2;

  --ion-color-tertiary: #cec1b6;
  --ion-color-tertiary-rgb: 206,193,182;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #b5aaa0;
  --ion-color-tertiary-tint: #d3c7bd;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  /* --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
	--ion-color-dark-tint: #f5f6f9; */
	--ion-color-dark: #222;
	--ion-color-dark-rgb: 34, 34, 34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #111;
	--ion-color-dark-tint: #444;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.light-gray-theme {
  --ion-background-color: var(--opx-background-color);
  --ion-background-color-rgb: var(--opx-background-color-rgb);

  --ion-text-color: var(--ion-color-primary);
	--ion-text-color-rgb: var(--ion-color-primary-rgb);
	--ion-item-border-color: #eee;
	--ion-toolbar-background: var(--ion-background-color);
	--ion-toolbar-color: var(--ion-color-primary);
	--ion-toolbar-color-activated: car(--ion-color-primary);


  /* --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d; */
}


/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.light-gray-theme {
  --ion-background-color: var(opx-background-color);
  --ion-background-color-rgb: var(--opx-background-color-rgb);

  --ion-text-color: var(--ion-color-primary);
	--ion-text-color-rgb: var(--ion-color-primary-rgb);
	--ion-item-border-color: #eee;
	--ion-toolbar-background: var(--ion-background-color);
	--ion-toolbar-color: var(--ion-color-primary);
	--ion-toolbar-color-activated: car(--ion-color-primary);

  /* --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e; */
}